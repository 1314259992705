import axios from 'axios';

const url = window.location.origin;

let rootApi = 'https://jagermeister.cookiefy.com';
if (url === 'https://jagermeister.cookiefy.com') {
  rootApi = url;
} else if (
  [
    'https://thebestnightsofyourlife.de',
    'https://thebestnightsofyourlife.com',
    'https://www.thebestnightsofyourlife.de',
    'https://www.thebestnightsofyourlife.com',
  ].includes(url)
) {
  rootApi = 'https://thebestnightsofyourlife.de';
}

const locale = localStorage.getItem('local') === 'en' ? 'en/' : '';

export default axios.create({
  baseURL: `${rootApi}/backend/${locale}wp-json/`,
  headers: {
    'Content-type': 'application/json',
  },
  auth: {
    username: 'tcl_user_rest_api',
    password: 'OGS5pXKc&hRz94883D',
  },
});
